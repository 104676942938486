import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/product/video/:code',
    name: 'index',
    component: () => import("@/views/index/index"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
